import spinner from "./assets/spinner.svg";

const Loader = () => {
    return (
        <div className="z-10 fixed w-screen h-screen bg-primary/70 flex items-center justify-center left-0 top-0">
            <div>
                <img alt="" src={spinner} />
                <p className="text-3xl font-bold text-primary-light text-center mt-5">
                    Loading...
                </p>
            </div>
        </div>
    );
};

export default Loader;
