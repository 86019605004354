import React from "react";

type _props = {
    type?: string;
    onChange?: any;
    value?: string;
    name?: string;
    maxLength?: number;
};

const Input = (props: _props) => {
    return <input {...props} required />;
};

export default Input;
