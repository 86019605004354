import { useEffect, useState } from 'react';
import bgbg from './assets/bgbg.png';
import logo from './assets/sofri-logo.png';
import Input from './Input';
import Loader from './Loader';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [data, setData] = useState({
    accountNumber: '',
    bvn: '',
    nin: '',
    residentialAddress: '',
  });

  const [err1, setErr1] = useState(false);
  const [err2, setErr2] = useState(false);
  const [err3, setErr3] = useState(false);
  const [err4, setErr4] = useState(false);
  const [err5, setErr5] = useState(false);
  const [msg, setMsg] = useState('');

  const [apiStat, setApiStat] = useState('');

  const [loading, setLoading] = useState(false);
  const url = 'https://api.sofri-verify.sofriwebservices.com/api/v1/users';

  async function submitForm(e: any) {
    e.preventDefault();
    if (
      data.accountNumber.length === 10 &&
      data.bvn.length === 11 &&
      data.nin.length === 11 &&
      data.residentialAddress
    ) {
      setLoading(true);
      try {
        let result: any = await axios.post(url, { ...data });
        if (result) {
          setApiStat('success');
          setMsg(result?.data.message);
          setData({
            accountNumber: '',
            bvn: '',
            nin: '',
            residentialAddress: '',
          });
        }
      } catch (err: any) {
        setApiStat('error');
        console.log(err?.response?.data?.error);
        setErr5(true);
      } finally {
        setLoading(false);
      }
    } else {
      if (data.accountNumber.length < 10) {
        setErr1(true);
      }
      if (data.bvn.length < 11) {
        setErr2(true);
      }
      if (data.nin.length < 11) {
        setErr3(true);
      }
    }
  }

  function formChange(e: any) {
    if (e.target.name === 'residentialAddress') {
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    } else {
      if (isNumber(e.target.value)) {
        setData({
          ...data,
          [e.target.name]: e.target.value,
        });
      }
    }
  }

  function isNumber(value: any) {
    if (isNaN(value)) {
      return false;
    }

    return !isNaN(value);
  }

  return (
    <>
      <ToastContainer style={{ zIndex: 100 }} />

      <div
        className="w-screen h-screen"
        style={{
          backgroundImage: `url(${bgbg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
        }}
      >
        <div className="w-full h-full bg-light/80 text-primary px-5">
          <div className="flex justify-center py-10">
            <a href="https://sofrisofri.com">
              <img alt="" src={logo} />
            </a>
          </div>
          <div className="my-10 max-w-xl mx-auto">
            <h1 className="text-center font-bold text-lg mb-10">
              Please verify your account to continue enjoying our services.
            </h1>
          </div>
          <form
            className="flex flex-col space-y-5 max-w-xl mx-auto"
            onSubmit={submitForm}
          >
            <div className="flex flex-col space-y-3">
              <label className="font-bold">Account Number</label>
              <Input
                name="accountNumber"
                type="text"
                value={data.accountNumber}
                onChange={formChange}
                maxLength={10}
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label className="font-bold">BVN</label>
              <Input
                name="bvn"
                type="text"
                value={data.bvn}
                onChange={formChange}
                maxLength={11}
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label className="font-bold">NIN</label>
              <Input
                name="nin"
                type="text"
                value={data.nin}
                onChange={formChange}
                maxLength={11}
              />
            </div>
            <div className="flex flex-col space-y-3">
              <label className="font-bold">Residential Address</label>
              <Input
                name="residentialAddress"
                type="text"
                value={data.residentialAddress}
                onChange={formChange}
              />
            </div>
            <div className="mx-auto">
              <button className="px-10 py-3 font-lg font-bold rounded-md bg-primary text-white">
                Submit
              </button>
            </div>
          </form>
        </div>
        {loading && <Loader />}
        {(err1 || err2 || err3 || err4 || apiStat || err5) && (
          <div className="z-10 fixed w-screen h-screen bg-primary/70 flex items-center justify-center left-0 top-0">
            <div className="bg-white rounded-2xl min-h-32 w-72 text-primary p-5 gap-3 flex flex-col relative">
              {apiStat === 'success' ? (
                <div className="flex flex-col items-center gap-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="green"
                    className="w-32 h-32"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>

                  <p className="text-lg font-semibold text-center">{msg}</p>
                </div>
              ) : (
                <div className="flex flex-col items-center gap-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="red"
                    className="w-10 h-10"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                  <p className="text-lg font-semibold text-center">
                    Error uploading KYC, try again!
                  </p>
                </div>
              )}

              {!apiStat && (
                <p className="text-2xl text-red-500 font-bold">
                  Errors {err1 ? 'accountNo' : err2 ? 'bvn' : err3 && 'nin'}
                </p>
              )}
              {err1 && (
                <p className="text-red-500">
                  Account Number must be 10 digits!
                </p>
              )}
              {err2 && <p className="text-red-500">BVN must be 11 digits!</p>}
              {err3 && <p className="text-red-500">NIN must be 11 digits!</p>}
              {err5 && (
                <p className="text-red-500">
                  Acount Number, NIN or BVN already exist
                </p>
              )}

              <div className="pt-5">
                <button
                  className="w-full py-3 px-4 text-white bg-primary font-semibold"
                  onClick={() => {
                    setErr1(false);
                    setErr2(false);
                    setErr3(false);
                    setErr4(false);
                    setErr5(false);
                    setApiStat('');
                  }}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default App;
